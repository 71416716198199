import {IPaystubData} from "../../Types/Interface/IPaystub";
import { v4 as uuidv4 } from 'uuid';
import {
    DeductionType,
    MaritalStatus,
    PaymentMode,
    paymentScheduleInfo,
    PaySchedule
} from "../../Types/Enums/PaymentModeEnum";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {addDays, formatDate} from "date-fns";
import {calculateYTD} from "../../Services/utils";

const initialState: IPaystubData = {
    company: {
        name: "",
        city: "",
        address: "",
        email: "",
        zipcode: "",
        state: "",
    },
    employee: {
        name: "",
        city: "",
        address: "",
        zipcode: "",
        state: "",
        ssn: "",
        maritalStatus: MaritalStatus.Single,
        eid: "",
        isContractor: false
    },
    info: {
        paymentMode: PaymentMode.Hourly,
        paySchedule: PaySchedule.Weekly,
        payStubCount: 1,
        hourlyRate: 20,
        salary: 48000,
    },
    payStubs: [
        {
            id: uuidv4(),
           from: formatDate(addDays(new Date(), -paymentScheduleInfo.Weekly.periodRange + 1), "yyyy-MM-dd"),
           to: formatDate(new Date(), "yyyy-MM-dd"),
           payDate: formatDate(new Date(), "yyyy-MM-dd"),
           earnings: [
               {
                   id: uuidv4(),
                   description: "Regular",
                   rate: 20,
                   type: PaymentMode.Hourly,
                   hours:  paymentScheduleInfo.Weekly.hours,
                   total: 20 * paymentScheduleInfo.Weekly.hours,
                   ytd: calculateYTD(20 * paymentScheduleInfo.Weekly.hours,  paymentScheduleInfo.Weekly.getCurrentPayPeriod())
               }
           ],
           deductions: [
               {
                   id: uuidv4(),
                   description: "Federal Tax",
                   type: DeductionType.FederalTax,
                   amount: 0,
                   ytd: 0,
               },
               {
                   id: uuidv4(),
                   description: "FICA - Social Security",
                   type: DeductionType.SocialSecurity,
                   amount: 0,
                   ytd: 0,
               },
               {
                   id: uuidv4(),
                   description: "FICA - Medicare",
                   type: DeductionType.Medicare,
                   amount: 0,
                   ytd: 0,
               },
               {
                   id: uuidv4(),
                   description: "State Tax",
                   type: DeductionType.StateTax,
                   amount: 0,
                   ytd: 0,
               }
           ],
            // todo edit gross pay to default
            grossPay: {
                amount: 20 * paymentScheduleInfo.Weekly.hours,
                ytd: calculateYTD(20 * paymentScheduleInfo.Weekly.hours, paymentScheduleInfo.Weekly.getCurrentPayPeriod())
            },
            netCheck: 0,
            netPay: 0,
            check: 0,
        }
    ]
}

const payStubSlice = createSlice({
    name: "paystub",
    initialState,
    reducers: {
        loadPaystub: (state, action: PayloadAction<IPaystubData>) => {
            return action.payload
        }
    }
})


export const selectPaystubData = (state: RootState) => state.payStub

export const {loadPaystub} = payStubSlice.actions
export default payStubSlice.reducer