import BusinessInfo from "../../../Components/Forms/BusinessInfo";
import {useFormikContext} from "formik";
import {IReceipt} from "../../../../Types/Interface/IReceipt";
import useHandleUploadLogo from "../../../../Hooks/useHandleUploadLogo";
import React from "react";

const prefix = "from."
const addressPrefix =  `${prefix}address.`

const ReceiptFrom = () => {
    const {values} = useFormikContext<IReceipt>()
    const handleLogoUpload = useHandleUploadLogo({companyEmail: values.from.email, companyName: values.from.name})



    return (
    <>
    <BusinessInfo
    formHeading={"Company Details"}
    currentStep={1}
    steps={4}
    prefix={prefix}
    addressPrefix={addressPrefix}
    handleLogoUpload={handleLogoUpload}
    hasWebsiteField/>
      </>
  )
}

export default ReceiptFrom