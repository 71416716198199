import {IPayStub, IPaystubData} from "../Types/Interface/IPaystub";
import {useCallback, useEffect, useMemo} from "react";
import {DeductionType, MaritalStatus, PaymentMode, paymentScheduleInfo} from "../Types/Enums/PaymentModeEnum";
import {
    calculateEarningYtd,
    calculateMedicareTax,
    calculateSocialSecurity,
    calculateYTD,
    mapEarningsTotal
} from "../Services/utils";
import {addDays, formatDate} from "date-fns";
import {v4 as uuidv4} from "uuid";
import {calculateFedTax} from "../Services/w2Service";

type SetFieldValue = (field: string, value: IPayStub[] | number) => void

const usePayModeEffect = ({values, setFieldValue}: {values: IPaystubData, setFieldValue: SetFieldValue}) => {

    const generatedStubs = useMemo((): IPayStub[] => {
        const payStubCount = values.info.payStubCount
        const prevCount = values.payStubs.length
        let payStubs
        if (prevCount > payStubCount) {
            // employee form count decrease
            payStubs = values.payStubs.slice(0, payStubCount)
        }else {
            // employee form count increases
            const emptyPayStubs = Array.from(Array<IPayStub>(payStubCount - prevCount))
            let prevStub = values.payStubs[prevCount - 1]
            console.log(prevCount, "prev count")
            const newStubs = emptyPayStubs.map((stub, index) => {
                const totalPaySalary = (values.info.salary === undefined ? 48000 : values.info.salary )/ paymentScheduleInfo[values.info.paySchedule].payPeriods()
                const totalGrossPay = 20 * paymentScheduleInfo[values.info.paySchedule].hours
                const total = values.info.paymentMode === PaymentMode.Hourly ? totalGrossPay : totalPaySalary
                const currentSalary = (values.info.salary === undefined ? 48000 : values.info.salary) / paymentScheduleInfo[values.info.paySchedule].payPeriods()
                const to = formatDate(addDays(new Date(prevStub.from), - 1), "yyyy-MM-dd")
                console.log(currentSalary, "current")

                // setting a default stub where after the rest are generated in the other loops below
                stub = {
                    id: uuidv4(),
                    to,
                    from: formatDate(addDays(new Date(prevStub.from), - paymentScheduleInfo[values.info.paySchedule].periodRange), "yyyy-MM-dd"),
                    payDate: to,
                    earnings:  [
                        {
                            id: uuidv4(),
                            description: "Regular",
                            rate: values.info.paymentMode === PaymentMode.Hourly ? 20 : undefined,
                            type: values.info.paymentMode,
                            hours: values.info.paymentMode === PaymentMode.Hourly ? paymentScheduleInfo[values.info.paySchedule].hours : undefined,
                            total,
                            ytd: calculateEarningYtd(prevCount + index, values.payStubs,  values.info.paySchedule, to, values.info.paymentMode, currentSalary, values.info.hourlyRate)
                        },
                        ...values.payStubs[0].earnings.slice(1).map((earning)=> ({...earning, id: uuidv4(), hours: undefined}))
                    ],
                    deductions: [
                        {
                            id: uuidv4(),
                            description: "Federal Tax",
                            type: DeductionType.FederalTax,
                            amount: 0,
                            ytd: 0,
                        },
                        {
                            id: uuidv4(),
                            description: "FICA - Social Security",
                            type: DeductionType.SocialSecurity,
                            amount: 0,
                            ytd: 0,
                        },
                        {
                            id: uuidv4(),
                            description: "FICA - Medicare",
                            type: DeductionType.Medicare,
                            amount: 0,
                            ytd: 0,
                        },
                        {
                            id: uuidv4(),
                            description: "State Tax",
                            type: DeductionType.StateTax,
                            amount: 0,
                            ytd: 0,
                        },
                        ...values.payStubs[0].deductions.slice(4).map((deduction)=> ({...deduction, id: uuidv4(), amount: 0}))
                    ],
                    netPay: 0,
                    netCheck: 0,
                    check: 0,
                    grossPay: {
                        amount: 0,
                        ytd: 0
                    }
                }
                prevStub = stub
                return stub
            })
            payStubs = values.payStubs.concat(newStubs)
        }
        return payStubs
    }, [values.info.payStubCount, values.payStubs.length])


    const payStubs = useMemo((): IPayStub[] => {
        /*
            When pay schedule changes i.e. from weekly to semi-monthly
             this loop recalculates the to-date and from-date for the whole list
         */
        let prevStub = generatedStubs[0]
        return generatedStubs.map((paystub, index) => {
            let newStub
            if (index === 0){
                newStub = {
                    ...paystub,
                    to: formatDate(new Date(prevStub.to), "yyyy-MM-dd"),
                    from: formatDate(addDays(new Date(prevStub.to), - paymentScheduleInfo[values.info.paySchedule].periodRange + 1), "yyyy-MM-dd"),
                    payDate: formatDate(new Date(prevStub.to), "yyyy-MM-dd"),
                }
            } else {
                newStub = {
                    ...paystub,
                    to: formatDate(addDays(new Date(prevStub.from), - 1), "yyyy-MM-dd"),
                    from: formatDate(addDays(new Date(prevStub.from), - paymentScheduleInfo[values.info.paySchedule].periodRange ), "yyyy-MM-dd"),
                    payDate: formatDate(addDays(new Date(prevStub.from), - 1), "yyyy-MM-dd"),
                }
            }
            prevStub = newStub
            return newStub
        })
    }, [values.payStubs, values.info.paySchedule, generatedStubs])
    const lastPayStubTo = useMemo(()=> payStubs[payStubs.length - 1].to, [payStubs])

    const payStubsWithChangedEarnings = useMemo(()=> {
        const hourlyTotal = (values.info.hourlyRate === undefined ? 20 : values.info.hourlyRate) * paymentScheduleInfo[values.info.paySchedule].hours
        return payStubs.map((paystub) => {
            const earnings = paystub.earnings
            console.log(earnings)
            if (values.info.paymentMode === PaymentMode.Hourly){
                earnings[0].description = "Regular"
                earnings[0].rate = values.info.hourlyRate
                earnings[0].hours = paymentScheduleInfo[values.info.paySchedule].hours
                earnings[0].total = hourlyTotal
            } else {
                const currentSalary = (values.info.salary === undefined ? 48000 : values.info.salary) / paymentScheduleInfo[values.info.paySchedule].payPeriods()

                earnings[0].description = "Salary"
                earnings[0].rate = undefined
                earnings[0].hours = undefined
                earnings[0].total = currentSalary
            }

            return{...paystub, earnings}
        })
    }, [payStubs, values.info.hourlyRate, paymentScheduleInfo[values.info.paySchedule].hours, values.info.paySchedule, values.info.paymentMode, values.info.salary])


    const generatePayStubs = useCallback((): IPayStub[] => {
        return payStubsWithChangedEarnings.map((paystub, index) => {
            const earnings = paystub.earnings
            if (values.info.paymentMode === PaymentMode.Hourly){
                earnings[0].ytd = calculateEarningYtd(index, payStubsWithChangedEarnings,  values.info.paySchedule, lastPayStubTo, values.info.paymentMode, 0, values.info.hourlyRate)
            } else {
                earnings[0].ytd = calculateYTD(earnings[0].total,  paymentScheduleInfo[values.info.paySchedule].getCurrentPayPeriod(paystub.to))
            }
            const grossPay = earnings.reduce((acc, current) => acc + current.total, 0)
            const grossYtd =  earnings.reduce((acc, current) => acc + current.ytd, 0)
            // todo calculate ytd for gross
            // todo calculate deductions
            const status = values.employee.maritalStatus === MaritalStatus.Single ? "single" : "married"

            return {
                ...paystub,
                grossPay: {amount: grossPay, ytd: grossYtd, },
                deductions:  paystub.deductions.map((deduction)=>{
                    switch (deduction.type) {
                        case DeductionType.FederalTax:
                            return {...deduction, amount: calculateFedTax(grossYtd, status), ytd: calculateYTD(calculateFedTax(grossYtd, status), paymentScheduleInfo[values.info.paySchedule].getCurrentPayPeriod(paystub.to))}
                        case DeductionType.SocialSecurity:
                            return {...deduction, amount: calculateSocialSecurity(grossYtd), ytd: calculateYTD(calculateSocialSecurity(grossYtd), paymentScheduleInfo[values.info.paySchedule].getCurrentPayPeriod(paystub.to))}
                        case DeductionType.Medicare:
                            return {...deduction, amount: calculateMedicareTax(grossYtd), ytd: calculateYTD(calculateMedicareTax(grossYtd), paymentScheduleInfo[values.info.paySchedule].getCurrentPayPeriod(paystub.to))}
                        case DeductionType.StateTax:
                            return deduction
                        case DeductionType.AdditionalDeduction:
                            return deduction
                    }
                }),
                earnings: mapEarningsTotal(earnings)
            }
        })
    }, [payStubsWithChangedEarnings, values.info.paymentMode, values.info.paySchedule, values.employee.maritalStatus, values.info.hourlyRate, lastPayStubTo, calculateEarningYtd])

    useEffect(() => {
        setFieldValue("payStubs", generatePayStubs())
    }, [values.info.paymentMode, values.info.hourlyRate, values.info.salary, values.info.paySchedule, values.info.payStubCount]);

    useEffect(()=>{
        if (values.info.paymentMode === PaymentMode.Salary){
            setFieldValue("info.salary", 48000)
        } else {
            setFieldValue("info.hourlyRate", 20)
        }
    }, [values.info.paymentMode])
}

export default usePayModeEffect