import {Box, Flex, FormControl, FormLabel, Input, Select, Text} from "@chakra-ui/react";
import React, {ChangeEvent} from "react"
import {IPaystubInfo} from "../../../../Types/Interface/IPaystub";
import {PaymentMode, PaySchedule} from "../../../../Types/Enums/PaymentModeEnum";

type PayStubInfoFormProps = {
    handleChange?: (e: ChangeEvent) => void
    info: IPaystubInfo,
}

type BaseInputProp = {
    value?: number | string,
    isChecked?: boolean,
    handleChange?: (e: ChangeEvent) => void
    setFieldValue?: (field: string, value: string | boolean | number) => void
}

const PayStubInfoForm = ({info, handleChange}: PayStubInfoFormProps) => {
    return (
        <Box mb={"49px"}>
            <PayStubInfoHeading/>
            <Flex gap={"16px"} my={"36px"}>
                <PayStubPaymentInfoSelector value={info.paymentMode} handleChange={handleChange}/>
                <PayStubPayScheduleSelector value={info.paySchedule} handleChange={handleChange}/>
                <PayStubHourlyRateInput mode={info.paymentMode} value={info.paymentMode === PaymentMode.Hourly ? info.hourlyRate : info.salary } handleChange={handleChange}/>
            </Flex>
        </Box>
    )
}

const PayStubInfoHeading = () => {
    return (
        <Box borderBottomWidth={"0.35px"} borderBottomColor={"black"} pb={"10px"}>
            <Text fontSize={"32px"} fontWeight={"semibold"} color={"#208c55"}>Paystub information</Text>
        </Box>
    )
}

const PayStubPaymentInfoSelector = ({value, handleChange}: BaseInputProp) => {
    return (
        <FormControl flexBasis={"33%"}>
            <FormLabel>
                How are you paid?
            </FormLabel>
            <Select variant={"filled"} isRequired value={value} onChange={handleChange} name={"info.paymentMode"}>
                <option value={PaymentMode.Hourly}>Hourly</option>
                <option value={PaymentMode.Salary}>Salary</option>
            </Select>
        </FormControl>
    )
}

const PayStubPayScheduleSelector = ({value, handleChange}: BaseInputProp) => {
    const paySchedule = Object(PaySchedule)

    return (
        <FormControl flexBasis={"33%"}>
            <FormLabel>
                Pay Schedule
            </FormLabel>
            <Select variant={"filled"} value={value} onChange={handleChange} name={"info.paySchedule"}>
                {
                    Object.keys(PaySchedule).map((schedule) => <option key={schedule} value={paySchedule[schedule]}>{paySchedule[schedule]}</option>)
                }
            </Select>
        </FormControl>
    )
}

const PayStubHourlyRateInput = ({value, handleChange, mode}: BaseInputProp & {mode: PaymentMode}) => {
    return (
        <FormControl flexBasis={"33%"}>
            <FormLabel>
                {
                    mode === PaymentMode.Hourly ? "Hourly Rate" : "Annual Salary"
                }
            </FormLabel>
            <Input value={value} onChange={handleChange} name={mode === PaymentMode.Hourly ? "info.hourlyRate" : "info.salary"} type={"number"}/>
        </FormControl>
    )
}


export default PayStubInfoForm