import {Box, Flex, Image, Table, Tbody, Td, Text, Th, Thead, Tr} from "@chakra-ui/react";
import amazon from "../../Assets/Images/Amazon.png"
import React, {CSSProperties, forwardRef} from "react";
import '@fontsource-variable/inter'
import {ICompany, IDeduction, IEarning, IEmployee, IPayStub} from "../../Types/Interface/IPaystub";
import {format} from "date-fns";
import {DeductionType} from "../../Types/Enums/PaymentModeEnum";
import converter from "number-to-words"


type StubTemplateProps = {
    company: ICompany,
    employee: IEmployee,
    paystub: IPayStub,
}

const generalTemplateStyle: CSSProperties = {
    fontFamily: "Inter Variable, sans-serif",
    color: "#000000",
    width: "100%",
    maxWidth: "800px",
    fontSize: "12px"
}

const commonTableHeadingStyle: CSSProperties = {
    ...generalTemplateStyle,
    width: "auto",
    margin: 0,
    borderBottomColor: "black",
    padding: "6px 0px",
    textTransform: "capitalize",
    fontWeight: "medium"
}

const tableDataStyle: CSSProperties = {
    ...generalTemplateStyle,
    minWidth: "40px",
    width: "max-content",
    margin: 0,
    padding: "8px 0px"
}

const tableColoredFooter = {
    backgroundColor: "rgba(0, 0, 0, 0.2)", padding: "9px 0px",  borderBottomColor: "black", borderTopColor: "black", borderTopWidth: "1px"
}

const tableCellSpace: CSSProperties = {width: "14px", padding: 0, borderBottom: 0}

const formatAddress = (address: string) => address.length > 0 ? `${address},`: ""

const StubTemplate = forwardRef<HTMLDivElement, StubTemplateProps>(function Stub({company, paystub, employee}, ref) {
    return (
        <Box style={generalTemplateStyle} ref={ref} as={"div"} borderColor={"primary"} border={"1px"}>
            {/*    Heading Section*/}
            <Flex px={"16px"} justifyContent={"space-between"} align={"end"}>
                <StubTemplateCompanyInfo company={company}/>
                <StubTemplatePeriodInfo from={format(paystub.from, "dd/MM/yyyy")} to={format(paystub.to, "dd/MM/yyyy")} payDate={format(paystub.payDate, "dd/MM/yyyy")}/>
            </Flex>
            <StubTemplateEmployeeInfo employee={employee}/>

            {/*    Body Section */}
            <StubTemplateTables paystub={paystub}/>

            {/* Advice info section*/}
            <StubTemplateAdviceInfo ssn={employee.ssn} payDate={format(paystub.payDate, "dd/MM/yyyy")}/>

            {/*    Footer Section*/}
            <StubTemplateBalanceFooter netPay={paystub.netPay}/>
        </Box>
    )
})


const StubTemplateTables = ({paystub}: {paystub: IPayStub}) => {
    const overtimes = paystub.earnings.filter((earning) => earning.description.toLowerCase().includes("overtime"))
    const holidays = paystub.earnings.filter((earning) => earning.description.toLowerCase().includes("holiday"))
    const vacations = paystub.earnings.filter((earning) => earning.description.toLowerCase().includes("vacation"))
    const bonuses = paystub.earnings.filter((earning) => earning.description.toLowerCase().includes("bonus"))
    const tips = paystub.earnings.filter((earning) => earning.description.toLowerCase().includes("tips"))
    const medicare = paystub.deductions.find((deduction) => deduction.type === DeductionType.Medicare)
    const socialSecurity = paystub.deductions.find((deduction) => deduction.type === DeductionType.SocialSecurity)
    const incomeFedTax = paystub.deductions.find((deduction) => deduction.type === DeductionType.FederalTax)
    const stateTax = paystub.deductions.find((deduction) => deduction.type === DeductionType.StateTax)
    const additionalDeductions = paystub.deductions.filter((deduction) => deduction.type === DeductionType.AdditionalDeduction)
    const additionalEarnings = paystub.earnings.slice(1).filter((earning) => {
        return !overtimes.includes(earning) && !holidays.includes(earning)
            && !vacations.includes(earning) && !bonuses.includes(earning)
            && !tips.includes(earning)
    })
    return (
        <Box minH={"358px"} mt={"24px"} px={"16px"}>
            <Flex justifyContent={"space-between"} gap={"16px"}>
                <Table style={commonTableHeadingStyle}>
                    <Thead>
                        <Tr>
                            <Th style={{...commonTableHeadingStyle, width: "100px"}}>Earnings</Th>
                            <Th style={commonTableHeadingStyle}>Rate</Th>
                            <Th style={{...commonTableHeadingStyle, width: "100px", textAlign: "center"}}>Hours</Th>
                            <Th style={{...commonTableHeadingStyle,  textAlign: "right"}}>Amount</Th>
                            <Th style={tableCellSpace}></Th>
                            <Th style={{...commonTableHeadingStyle,  textAlign: "right"}}>Year to date</Th>
                        </Tr>
                    </Thead>
                    <Tbody mb={"24px"}>
                        <EarningRow earning={paystub.earnings[0]}/>
                        {
                            overtimes.map((overtime) => <EarningRow key={overtime.id} earning={overtime}/>)
                        }
                        {
                            holidays.map((holiday) => <EarningRow key={holiday.id} earning={holiday}/>)
                        }
                        {
                            vacations.map((vacation) => <EarningRow key={vacation.id} earning={vacation}/>)
                        }
                        {
                            bonuses.map((bonus) => <EarningRow key={bonus.id} earning={bonus}/>)
                        }
                        {
                            tips.map((tip) => <EarningRow key={tip.id} earning={tip}/>)
                        }


                        <Tr style={{padding: 0}}>
                            <Td style={tableCellSpace}></Td>
                            <Td colSpan={2} style={tableColoredFooter}>GROSS PAY</Td>
                            <Td style={{...tableColoredFooter, textAlign: "right"}}>{paystub.grossPay.amount.toFixed(2)}</Td>
                            <Td style={tableCellSpace}></Td>
                            <Td style={{...tableDataStyle, borderBottom: 0,  textAlign: "right"}}>{paystub.grossPay.ytd.toFixed(2)}</Td>
                        </Tr>
                        <Tr>
                            <Td style={{padding: "8px 0px", borderBottom: 0}}></Td>
                        </Tr>
                    </Tbody>
                    <Thead>
                        <Tr>
                            <Th style={{...commonTableHeadingStyle, width: "100px"}}>Deduction</Th>
                            <Th colSpan={2} style={commonTableHeadingStyle}>Statutory</Th>
                            <Th style={commonTableHeadingStyle}></Th>
                            <Th style={tableCellSpace}></Th>
                            <Th style={commonTableHeadingStyle}><span style={{visibility: "hidden"}}>Year to Date</span></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            incomeFedTax && <DeductionRow deduction={incomeFedTax}/>
                        }
                        {
                            socialSecurity && <DeductionRow deduction={socialSecurity}/>
                        }
                        {
                            medicare && <DeductionRow deduction={medicare}/>
                        }
                        {
                            stateTax && <DeductionRow deduction={stateTax}/>
                        }
                        <Tr>
                            <Td style={{padding: "8px 0px", borderBottom: 0}}></Td>
                        </Tr>
                        {
                            additionalDeductions.length > 0 && (
                                <>
                                    <Tr >
                                        <Td style={tableCellSpace}></Td>
                                        <Td colSpan={2} style={{...tableDataStyle, borderBottom: 0, textDecoration: "underline"}}>Other</Td>
                                    </Tr>
                                    {
                                        additionalDeductions.map((deduction) => <DeductionRow key={deduction.id} deduction={deduction}/> )
                                    }
                                </>
                            )
                        }

                        <Tr>
                            <Td style={tableCellSpace}></Td>
                            <Td colSpan={2} style={{...tableColoredFooter, borderTopWidth: "1px", borderColor: "black"}}>NET PAY</Td>
                            <Td style={{...tableColoredFooter, borderTopWidth: "1px", borderColor: "black"}}>{paystub.netPay.toFixed(2)}</Td>
                        </Tr>
                        <Tr>
                            <Td style={tableCellSpace}></Td>
                            <Td colSpan={2} style={{...tableDataStyle, borderBottomColor: "black"}}>CHECK</Td>
                            <Td style={{...tableDataStyle, borderBottomColor: "black"}}>{paystub.check.toFixed(2)}</Td>
                        </Tr>
                        <Tr>
                            <Td style={tableCellSpace}></Td>
                            <Td colSpan={2} style={tableColoredFooter}>NET CHECK</Td>
                            <Td style={tableColoredFooter}>{paystub.netCheck.toFixed(2)}</Td>
                        </Tr>
                    </Tbody>
                </Table>

                <Box flexGrow={1}>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th style={{...commonTableHeadingStyle, borderBottomColor: "black"}}>Other benefit</Th>
                                <Th style={{...commonTableHeadingStyle, borderBottomColor: "black"}}>This period</Th>
                                <Th style={tableCellSpace}></Th>
                                <Th style={{...commonTableHeadingStyle, borderBottomColor: "black"}}>Year to date</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                additionalEarnings.map(earning => <AdditionalEarningRow key={earning.id} earning={earning}/>)
                            }
                        </Tbody>
                    </Table>

                </Box>
            </Flex>
        </Box>
    )
}

const DeductionRow = ({deduction}: {deduction: IDeduction}) => {
    return (
        <Tr>
            <Td style={tableCellSpace}></Td>
            <Td colSpan={2} style={{...tableDataStyle, borderBottom: 0}}>{deduction.description}</Td>
            <Td style={{...tableDataStyle, borderBottom: 0}}>{deduction.amount.toFixed(2)}</Td>
            <Td style={{...tableCellSpace, borderBottom: 0}}></Td>
            <Td style={{...tableDataStyle, borderBottom: 0, textAlign: "right"}}>{deduction.ytd.toFixed(2)}</Td>
        </Tr>
    )
}

const EarningRow = ({earning}: {earning: IEarning})=> {
    return (
        <Tr>
            <Td style={{...tableDataStyle, borderBottom: 0}}>{earning.description}</Td>
            <Td style={{...tableDataStyle, borderBottom: 0, }}>{earning.rate}</Td>
            <Td style={{...tableDataStyle, borderBottom: 0, textAlign: "center"}}>{earning.hours}</Td>
            <Td style={{...tableDataStyle, borderBottom: 0, textAlign: "right"}}>{earning.total.toFixed(2)}</Td>
            <Td style={tableCellSpace}></Td>
            <Td style={{...tableDataStyle, borderBottom: 0,  textAlign: "right"}}>{earning.ytd.toFixed(2)}</Td>
        </Tr>
    )
}
const AdditionalEarningRow = ({earning}: {earning: IEarning})=> {
    return (
        <Tr>
            <Td style={{...tableDataStyle, borderBottom: 0}}>{earning.description}</Td>
            <Td style={{...tableDataStyle, borderBottom: 0}}>{earning.total.toFixed(2)}</Td>
            <Td style={{...tableCellSpace, borderBottom: 0}}></Td>
            <Td style={{...tableDataStyle, borderBottom: 0}}>{earning.ytd.toFixed(2)}</Td>
        </Tr>
    )
}

const StubTemplateEmployeeInfo = ({employee}: {employee: IEmployee}) => {
    return (
        <Flex px={"16px"} mt={"50px"} align={"start"} justifyContent={"space-between"}>
            <Box flexBasis={"40%"} fontSize={"12px"} color={"#000000"}>
                <Flex justifyContent={"space-between"}>
                    <Text>Social Security Number:</Text>
                    <Text>XXX-XX-{employee.ssn.length === 0 ? 1234 : employee.ssn}</Text>
                </Flex>
                <Flex justifyContent={"space-between"}><Text>Taxable Marital Status:</Text> <Text>{employee.maritalStatus}</Text></Flex>
                <Flex justifyContent={"space-between"}><Text>Exemptions / Allowances</Text> <Text>0</Text></Flex>
                <Flex justifyContent={"space-between"}><Text>Employee ID:</Text> <Text>{employee.eid}</Text></Flex>
            </Box>
            <Box flexBasis={"40%"} fontWeight={"medium"} color={"#1a1c21"}>
                <Box w={"139px"} pr={'8px'} fontSize={"12px"}>
                    <Text>{employee.name}</Text>
                    <Text>{formatAddress(employee.address)}{formatAddress(employee.city)}{formatAddress(employee.state)}{employee.zipcode}.</Text>
                </Box>
            </Box>
        </Flex>
    )
}

const StubTemplatePeriodInfo = ({from, to, payDate}: {from: string, to: string, payDate: string}) => {
    return (
        <Box flexBasis={"40%"}>
            <Text fontSize={"24"} textAlign={"end"} fontWeight={"semibold"}>Earning Statement</Text>
            <Box fontSize={"12px"} my={"24px"} pr={"8px"} fontWeight={"medium"}>
                <Flex justifyContent={"space-between"}><Text color={"#1a1c21"}>Period Beginning</Text> <Text
                    color={"#5e6470"}>{from}</Text></Flex>
                <Flex justifyContent={"space-between"}><Text color={"#1a1c21"}>Period Ending</Text> <Text
                    color={"#5e6470"}>{to}</Text></Flex>
                <Flex justifyContent={"space-between"}><Text color={"#1a1c21"}>Pay Date</Text> <Text
                    color={"#5e6470"}>{payDate}</Text></Flex>
            </Box>
        </Box>
    )
}

const StubTemplateCompanyInfo = ({company}: {company: ICompany}) => {
    return (
        <Box flexBasis={"33%"}>
            {/*    Logo*/}
            <Flex direction={"column"} justifyContent={"end"} h={"100px"} w={"100px"} mb={"50px"}>
                <Image src={company.logo ?? amazon} objectFit={"contain"}/>
            </Flex>
            {/*    Company Info*/}
            <Box w={"139px"} pr={'12px'} fontSize={"12px"} color={"#1a1c12"} fontWeight={"medium"}>
                {/*Company Name*/}
                <Text>{company.name}</Text>
                {/*    Company Address*/}
                <Text>{formatAddress(company.address)}{formatAddress(company.city)}{formatAddress(company.state)}{company.zipcode}</Text>
            </Box>
        </Box>
    )
}

const StubTemplateAdviceInfo = ({ssn, payDate}: {ssn: string, payDate: string}) => {
    return (
        <Flex px={"16px"} h={"179px"} justifyContent={"end"} align={"center"} mb={"24px"}>
            <Box flexBasis={"50%"} pr={"36px"} fontSize={"12px"} fontWeight={"medium"} color={"#1a1c21"}>
                {/*<Flex justifyContent={"space-between"}>*/}
                {/*    <Text>Advice Number:</Text>*/}
                {/*    <Text color={"#5e6470"}>00345563</Text>*/}
                {/*</Flex>*/}
                <Flex justifyContent={"space-between"}><Text>Pay Date</Text> <Text
                    color={"#5e6470"}>{payDate}</Text></Flex>
                <Flex justifyContent={"space-between"}><Text>Social security number</Text> <Text
                    color={"#5e6470"}>XXX-XX-{ssn}</Text></Flex>
            </Box>
        </Flex>
    )
}

const StubTemplateBalanceFooter = ({netPay}: {netPay: number}) => {
    const cents = netPay.toFixed(2).split(".")[1]
    return (
        <Box fontSize={"12px"} pt={"46px"} pb={"89px"} px={"66px"} backgroundColor={"#f9fafc"}>
            <Text fontWeight={"medium"} mb={"8px"}>
                Earnings
            </Text>
            <Flex borderTopWidth={"0.69px"} borderColor={"#000000"}>
                <Box py={"8px"} pl={"4px"} pr={"16px"}>
                    <Text>
                        This amount
                    </Text>
                </Box>
                <Flex fontWeight={"semibold"} flexGrow={"1"} borderBottomWidth={"0.69px"} borderColor={"#000000"}>
                    <Box py={"8px"} flexBasis={"80%"}>
                        <Text pl={"16px"} textTransform={"uppercase"}>
                            {
                                `${converter.toWords(parseInt(netPay.toString()))} Dollars ${cents !== "00" ? "And" : ""} ${cents !== "00" ? converter.toWords(cents) : ""} ${cents !== "00" ? "Cents" : ""}`
                            }
                        </Text>
                    </Box>
                    <Box pl={"36px"} py={"8px"} flexBasis={"20%"} borderLeftWidth={"0.69px"} borderColor={"#000000"}>
                        <Text>
                            ${netPay.toFixed(2)}
                        </Text>
                    </Box>
                </Flex>
            </Flex>
        </Box>
    )
}

export default StubTemplate
export {StubTemplate}