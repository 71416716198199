import {
    Box,
    Flex, NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Text
} from "@chakra-ui/react";
import FormHeading from "../../Components/FormHeading";
import EmployeeInfoForm from "./Forms/EmployeeInfoForm";
import PayStubInfoForm from "./Forms/PayStubInfoForm";
import CompanyInfoForm from "./Forms/CompanyInfoForm";
import PeriodicPayStubSection from "./PeriodicPayStubSection";
import React, {ChangeEvent} from 'react'
import {IEarning, IPaystubData} from "../../../Types/Interface/IPaystub";

type HandleChange = (e: ChangeEvent) => void
type SetFieldValue = (field: string, value: string | boolean | number | IEarning[] | IEarning) => void

const PayStubForms = ({handleChange, values, setFieldValue}: {handleChange: HandleChange, values: IPaystubData, setFieldValue: SetFieldValue}) => {


    return (
        <Box flexBasis={"50%"}>
            <FormHeading title={"Create Paystub"}
                         description={"Fill in your information below."}/>
            <EmployeeInfoForm employeeData={values.employee} handleChange={handleChange}/>
            <PayStubInfoForm info={values.info} handleChange={handleChange}/>
            <CompanyInfoForm company={values.company} handleChange={handleChange}/>
            <PayStubsCountInput value={values.info.payStubCount} setFieldValue={setFieldValue}/>
            <PeriodicPayStubSection paystubs={values.payStubs} handleChange={handleChange} setFieldValue={setFieldValue}/>
        </Box>
    )
}

const PayStubsCountInput = ({setFieldValue, value}: {setFieldValue: SetFieldValue, value: number}) => {
    return (
        <Flex mb={"24px"}>
            <Text mr={"24px"} fontSize={'20px'} fontWeight={"semibold"} color={"#208c55"}>
                How many paystubs do you need
            </Text>
            <NumberInput value={value} min={1} max={12} onChange={(valueString) => setFieldValue("info.payStubCount", valueString.length > 0 ? parseInt(valueString) : 1)} name={"info.payStubCount"}>
                <NumberInputField/>
                <NumberInputStepper>
                    <NumberIncrementStepper/>
                    <NumberDecrementStepper/>
                </NumberInputStepper>
            </NumberInput>
        </Flex>
    )
}

export default PayStubForms