import {Box, Button, Checkbox, Flex, FormControl, FormLabel, Input, Text} from "@chakra-ui/react";
import PlusIcon from "../../../../Components/PlusIcon";
import React, {ChangeEvent, useEffect, useState} from "react";
import {IEarning, IPayStub} from "../../../../Types/Interface/IPaystub";
import {v4 as uuidv4} from "uuid";
import {PaymentMode} from "../../../../Types/Enums/PaymentModeEnum";

type HandleChange = (e: ChangeEvent) => void

type SetFieldValue = (field: string, value: number | IEarning[] | IEarning) => void

type AdditionalEarningProps = {
    index: number,
    earnings: IEarning[],
    handleChange: HandleChange,
    setFieldValue: SetFieldValue,
    payStubs: IPayStub[],
}

const AdditionalEarnings = ({index, earnings, handleChange, setFieldValue, payStubs,}: AdditionalEarningProps) => {

    const [canAddEarnings, setCanAddEarnings] = useState(false)
    const [drafts, setDrafts] = useState<IEarning & {index: number} | undefined>()

    useEffect(() => {
        console.log(drafts, "drafts")
        if (drafts !== undefined){
            payStubs.forEach((paystub, stubIndex) => {
                if (stubIndex !== 0){
                    const prevStubEarnings = paystub.earnings
                    console.log(prevStubEarnings)
                    console.log(prevStubEarnings[drafts.index + 1])
                    prevStubEarnings[drafts.index + 1].description = drafts.description
                    prevStubEarnings[drafts.index + 1].rate = drafts.rate
                    setFieldValue(`payStubs[${stubIndex}].earnings[${drafts.index + 1}]`, prevStubEarnings[drafts.index])
                }
            })
        }
        
    }, [drafts?.description, drafts?.index, drafts?.rate, payStubs]);

    const generateNewEarning = () : IEarning => {
        return {
            id: uuidv4(),
            description: "",
            rate: undefined,
            hours: undefined,
            type: PaymentMode.Hourly,
            total: 0,
            ytd: 0
        }
    }



    const addEarning = () => {
        payStubs.forEach((paystub, stubIndex) => {
            const initialEarning = paystub.earnings[0]
            console.log(`${stubIndex}: ${initialEarning}, adding earning`)
            const newEarning = generateNewEarning()
            console.log(newEarning)
            setFieldValue(`payStubs[${stubIndex}].earnings`, [initialEarning, newEarning])
        })
        setCanAddEarnings(true)
    }

    const removeAllEarnings = () => {
        setDrafts(undefined)

        payStubs.forEach((paystub, stubIndex) => {
            const initialEarning = paystub.earnings[0]
            console.log(`${stubIndex}: ${initialEarning}, removing`)

            setFieldValue(`payStubs[${stubIndex}].earnings`, [initialEarning])
        })
        setCanAddEarnings(false)
    }


    return (
        <>
            {
                index === 0 ? (
                    <Flex align={"center"} justifyContent={"space-between"} mt={"60px"} mb={"36px"}>
                        <Box>
                            <Text color={"#208c55"} fontSize={"18px"}>
                                Additional earnings
                            </Text>
                            <Text color={"#0000007F"}>
                                Add Bonuses, Overtime, tips, expenses
                            </Text>
                        </Box>
                        <Checkbox isChecked={canAddEarnings} color={"#208c55"} onChange={() => !canAddEarnings ? addEarning() : removeAllEarnings() } colorScheme={"green"} fontWeight={"normal"} borderColor={"#208c55"}  variant={"outline"}>
                            Add Earnings
                        </Checkbox>
                    </Flex>
                ) : (
                    earnings.length > 0 && (
                        <Text color={"#208c55"} fontSize={"18px"} my={"16px"}>
                            Additional earnings
                        </Text>
                    )
                )
            }
            {
                earnings.map((earning, earningIndex) => (
                    <Flex key={earning.id} gap={"8px"} mb={"16px"}>
                        <FormControl>
                            <FormLabel color={"#208c55"}>
                                Description
                            </FormLabel>
                            <Input value={earning.description}
                                   disabled={index !== 0}
                                   onChange={handleChange}
                                   onBlur={(e) => {
                                       if (index === 0) {
                                           setDrafts({...earning, description: e.target.value, index: earningIndex})   
                                       }
                                   }}
                                   name={`payStubs[${index}].earnings[${earningIndex + 1}].description`}
                                   type={"text"}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel color={"#208c55"}>
                                Rate
                            </FormLabel>
                            <Input value={earning.rate}
                                   disabled={index !== 0}
                                   onChange={handleChange}
                                   onBlur={(e) => {
                                       if (index === 0) {
                                           setDrafts({...earning, rate: parseFloat(e.target.value), index: earningIndex})
                                       }
                                   }}
                                   name={`payStubs[${index}].earnings[${earningIndex + 1}].rate`}
                                   type={"number"}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel color={"#208c55"}>
                                Hours / Quantity
                            </FormLabel>
                            <Input value={earning.hours}
                                   onChange={handleChange}
                                   name={`payStubs[${index}].earnings[${earningIndex + 1}].hours`}
                                   type={"number"}/>
                        </FormControl>
                    </Flex>
                ))
            }
        </>
    )
}

export default AdditionalEarnings