import { reqClient } from "Api/reqClient"
import { useCallback, useEffect, useState } from "react"
import IW2 from "Types/Interface/IW2"
import { useDebouncedCallback } from "use-debounce";

const useFillW2 = (w2Data: IW2) => {
    const [data, setData] = useState<File>()
    const [loading, setLoading] = useState(false);
    const refreshData = useCallback(async () => {
        setLoading(true)
        console.log("reqClient....", reqClient.getUri())
        try {
            const fData = await reqClient.post("/fillW2", {
                "dto": {
                    "taxYear": w2Data.year,
                    "numberOfForms": w2Data.formCount,
                    "employerInfo": {
                        "name": w2Data.employer.companyName,
                        "email": w2Data.employer.email,
                        "addressLine1": w2Data.employer.address.stateAddress,
                        "city": w2Data.employer.address.city,
                        "addressLine2": "",
                        "zipCode": w2Data.employer.address.zipcode,
                        "phoneNumber": "",
                        "logo": "",
                        "imageFilePath": "",
                        "imageFileName": "",
                        "ein": w2Data.employer.taxId,
                        "stateTaxEINId": w2Data.employer.stateId,
                        "controlNumber": w2Data.employees[0].controlNumber,
                        "state": w2Data.employer.address.state,
                        "employerStateIdNumber": w2Data.employer.stateId
                    },
                    "employee": {
                        "firstName": w2Data.employees[0].firstName,
                        "lastName": w2Data.employees[0].lastName,
                        "addressLine1": w2Data.employees[0].address.stateAddress,
                        "addressLine2": "",
                        "zip": w2Data.employees[0].address.zipcode,
                        "city": w2Data.employees[0].address.city,
                        "state": w2Data.employees[0].address.state,
                        "ssn": w2Data.employees[0].ssn,
                        "employeeNumber": "",
                        "email": w2Data.employees[0].email,
                        "isMarried": w2Data.employees[0].maritalStatus === "married",
                        "annualIncome": w2Data.employees[0].income,
                        "dependentCareBenefits": w2Data.employees[0].dependentCareBenefits,
                        "socialSecurityTips": w2Data.employees[0].tips.socialSecurity,
                        "socialSecurityWages": w2Data.employees[0].income,
                        "allocatedTips": w2Data.employees[0].tips.allocated,
                        "statutoryEmployee": w2Data.employees[0].isStatutory ? "On" : "Off",
                        "retirementPlan": w2Data.employees[0].hasRetirementPlan ? "On" : "Off",
                        "thirdPartySickPay": w2Data.employees[0].receivesSickPay ? "On" : "Off",
                        "taxCode12A": w2Data.employees[0].taxCodeA.code,
                        "taxCode12AValue": w2Data.employees[0].taxCodeA.amount,
                        "taxCode12B": w2Data.employees[0].taxCodeB.code,
                        "taxCode12BValue": w2Data.employees[0].taxCodeB.amount,
                        "taxCode12C": w2Data.employees[0].taxCodeC.code,
                        "taxCode12CValue": w2Data.employees[0].taxCodeC.amount,
                        "taxCode12D": w2Data.employees[0].taxCodeD.code,
                        "taxCode12DValue": w2Data.employees[0].taxCodeD.amount,
                        "localWagesTips": w2Data.employees[0].localIncome.income,
                        "localIncomeTax": w2Data.employees[0].localIncome.tax,
                        "wagesTipsOtherCompensation": w2Data.employees[0].income,
                        "tax": {
                            "federalIncomeTax": w2Data.employees[0].tax.federalIncome,
                            "socialSecurityTax": w2Data.employees[0].tax.socialSecurity,
                            "medicareTax": w2Data.employees[0].tax.medicare,
                            "stateIncomeTax": w2Data.employees[0].tax.stateIncome ?? 0.00
                        },
                        "suffix": "",
                        "initials": "",
                        "zipCode": w2Data.employees[0].address.zipcode,
                        "medicareWagesAndTips": w2Data.employees[0].income,
                        "stateWagesTips": w2Data.employees[0].income,
                        "stateIncomeTax": w2Data.employees[0].tax.stateIncome ?? 0.00,
                        "localityName": w2Data.employees[0].localIncome.name
                    }
                }
            }, { responseType: "blob" })
            setData(fData.data)
        } catch (e) {
            console.log("exception...", e)
        }
        setLoading(false)
    }, [w2Data.employees, w2Data.employer.address.state, w2Data.employer.address.stateAddress, w2Data.employer.address.zipcode, w2Data.employer.companyName, w2Data.employer.email, w2Data.employer.stateId, w2Data.employer.taxId, w2Data.formCount, w2Data.year])

    const debounce = useDebouncedCallback(
        refreshData, 2000
    )

    useEffect(() => {
        debounce()
    }, [debounce, refreshData])

    return { data, refreshData, loading }
}

export default useFillW2