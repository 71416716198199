import {
    Box,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Input,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Text
} from "@chakra-ui/react";
import React, {ChangeEvent} from "react"
import {IEarning, IPayStub} from "../../../Types/Interface/IPaystub";
import AdditionalEarnings from "./Forms/AdditionalEarnings";
import CustomDeductions from "./Forms/CustomDeductions";
import {PaymentMode} from "../../../Types/Enums/PaymentModeEnum";

type HandleChange = (e: ChangeEvent) => void
type SetFieldValue = (field: string, value: string | boolean | number | IEarning[] | IEarning) => void

type BaseInputProp = {
    index: number;
    value?: number | string ,
    isChecked?: boolean,
    handleChange?: HandleChange
}


const PeriodicPayStubSection = ({paystubs, handleChange, setFieldValue}: {paystubs: IPayStub[], handleChange: HandleChange, setFieldValue: SetFieldValue}) => {
  return (
      <>
          {
              paystubs.map((paystub, index) => (
                  <Box key={paystub.id} p={"32px"} mb={'24px'} borderRadius={"8px"} borderWidth={"1px"} borderColor={"#E2E4E5"}>
                      <PeriodicPayStubHeader index={index}/>
                      <Flex gap={"48px"} my={"32px"}>
                          <PayDateStartInput value={paystub.from} index={index} handleChange={handleChange} />
                          <PayDateEndInput value={paystub.to} index={index} handleChange={handleChange}/>
                      </Flex>
                      <Flex gap={"48px"}>
                          <PayDateInput  value={paystub.payDate} index={index} handleChange={handleChange}/>
                          <TotalHoursSelector value={paystub.earnings[0].hours} mode={paystub.earnings[0].type} index={index} setFieldValue={setFieldValue}/>
                      </Flex>
                      <AdditionalEarnings index={index} payStubs={paystubs} setFieldValue={setFieldValue} earnings={paystub.earnings.slice(1)} handleChange={handleChange}/>
                      <CustomDeductions index={index}/>
                  </Box>
              ))
          }
      </>
  )
}

const PeriodicPayStubHeader = ({index}: {index: number}) => {
  return (
      <Flex justifyContent={"space-between"}>
        <Text fontSize={"24px"} fontWeight={"semibold"} color={"#208c55"}>
          PayStub {index + 1}
        </Text>
        <Checkbox>
          Add Deposit slip
        </Checkbox>
      </Flex>
  )
}

const PayDateStartInput = ({handleChange, value, index}: BaseInputProp) => {
  return (
      <FormControl>
        <FormLabel>
          Pay Date Start
        </FormLabel>
        <Input value={value}
               onChange={handleChange}
               name={`payStubs[${index}].from`}
               type={"date"}/>
      </FormControl>
  )
}

const PayDateEndInput = ({handleChange, value, index}: BaseInputProp) => {
  return (
      <FormControl>
        <FormLabel>
          Pay Date End
        </FormLabel>
        <Input
            value={value}
            onChange={handleChange}
            name={`payStubs[${index}].to`}
            type={"date"}/>
      </FormControl>
  )
}

const PayDateInput = ({handleChange, value, index}: BaseInputProp) => {
  return (
      <FormControl>
        <FormLabel>
          Pay Date
        </FormLabel>
        <Input value={value}
               onChange={handleChange}
               name={`payStubs[${index}].payDate`}
               type={"date"}/>
      </FormControl>
  )
}

const TotalHoursSelector = ({setFieldValue, value, index, mode}: BaseInputProp & {setFieldValue: SetFieldValue, mode: PaymentMode}) => {
  return (
      <FormControl isRequired>
        <FormLabel>
          Total Hours
        </FormLabel>
          <NumberInput isRequired value={value} isDisabled={mode === PaymentMode.Salary} onChange={(valueString) => setFieldValue(`payStubs[${index}].earnings[0].hours`, valueString.length > 0 ? parseInt(valueString) : "")}>
          <NumberInputField/>
          <NumberInputStepper>
              <NumberIncrementStepper/>
              <NumberDecrementStepper/>
          </NumberInputStepper>
      </NumberInput>
      </FormControl>
  )
}

export default PeriodicPayStubSection