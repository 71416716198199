import {Box, Flex, Text, FormControl, FormLabel, Input, Select} from "@chakra-ui/react";
import React, {ChangeEvent} from "react"
import {ICompany} from "../../../../Types/Interface/IPaystub";
import StateDropSelect from "../../../Components/StateDropSelect";

type CompanyFormProps = {
    handleChange?: (e: ChangeEvent) => void
    company: ICompany,
}

type BaseInputProp = {
    value?: number | string,
    isChecked?: boolean,
    handleChange?: (e: ChangeEvent) => void
    setFieldValue?: (field: string, value: string | boolean | number) => void
}

const CompanyInfoForm = ({company, handleChange}: CompanyFormProps) => {
    return (
        <Box mb={"49px"}>
            <CompanyInfoHeading/>
            <Flex gap={"24px"} mb={"30px"}>
                <CompanyNameInput value={company.name} handleChange={handleChange}/>
                <CompanyAddressInput value={company.address} handleChange={handleChange}/>
            </Flex>
            <Flex gap={"24px"} align={"end"}>
                <CompanyCityInput value={company.city} handleChange={handleChange}/>
                <CompanyStateSelector value={company.state} handleChange={handleChange}/>
                <CompanyZipCodeInput value={company.zipcode} handleChange={handleChange}/>
            </Flex>
        </Box>
    )
}

const CompanyInfoHeading = () => {
    return (
        <Box mb={"30px"}  borderBottomWidth={"0.35px"} borderBottomColor={"black"} pb={"10px"}>
            <Text fontSize={"32px"} fontWeight={"semibold"} color={"#208c55"} >
                Company&apos;s Information
            </Text>
        </Box>
    )
}

const CompanyCityInput = ({handleChange, value}: BaseInputProp) => {
    return (
        <FormControl>
            <FormLabel>
                City
            </FormLabel>
            <Input value={value} onChange={handleChange} name={"company.city"} type={"text"}/>
        </FormControl>
    )
}

const CompanyAddressInput = ({handleChange, value}: BaseInputProp) => {
    return (
        <FormControl>
            <FormLabel>
                Company Address
            </FormLabel>
            <Input value={value} onChange={handleChange} name={"company.address"} type={"text"}/>
        </FormControl>
    )
}

const CompanyNameInput = ({handleChange, value}: BaseInputProp) => {
    return (
        <FormControl flexBasis={"50%"}>
            <FormLabel>
                Company Name
            </FormLabel>
            <Input value={value} onChange={handleChange} name={"company.name"}  type={"text"}/>
        </FormControl>
    )
}

const CompanyStateSelector = ({handleChange, value}: BaseInputProp) => {
    return (
        <StateDropSelect flexBasis={"40%"} label={"State"} placeholder={"Select state"} notFormik name={"company.state"} value={value} onChange={handleChange}/>
    )
}

const CompanyZipCodeInput = ({handleChange, value}: BaseInputProp) => {
    return (
        <FormControl flexBasis={"25%"}>
            <FormLabel>
                ZIP Code
            </FormLabel>
            <Input value={value} onChange={handleChange} name={"company.zipcode"} type={"text"}/>
        </FormControl>
    )
}

export default CompanyInfoForm