import React, { forwardRef } from "react";
import { useCalculateInvoice } from "Hooks";
// import { useAppSelector } from "Hooks/useAppSelector";
import { Document } from "Store/Slices";
import Logo from '../../Assets/Svg/Logo.svg'
import WaterMark from "./WaterMark";
import { currencyFormatter } from "Services/utils";


interface ITableProps {
    values: Document;
    logoUrl?: string;
}

export const QuotesTemplate = forwardRef((props: ITableProps & { hasPaid?: boolean }, ref: React.Ref<HTMLDivElement>) => {
    // const document = useAppSelector((state) => state.document);
    const { subtotal, discount, itemsLineTotal, tax, total } = useCalculateInvoice(
        props.values.lineItems,
        props.values.discountPercentage,
        props.values.discountAmount,
        props.values.taxAmount
    );

    return (
        <div ref={ref} style={{
            fontFamily: 'Inter, sans-serif', padding: '0px'
        }}>
            <div style={{
                border: '1px solid #1fbc6c80', overflow: "clip", position: "relative", maxWidth: '800px', height: 'auto', margin: 'auto', backgroundColor: '#fff'
            }}>
                {
                    !props.hasPaid && <WaterMark font={"96px"} left={"40px"} />
                }
                <div style={{
                    padding: '24px', paddingRight: '50px', paddingLeft: '50px', width: 'auto', backgroundColor: '#F9FAFC', display: 'flex',
                    justifyContent: 'space-between', alignItems: 'center'
                }}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'left', alignItems: 'left' }}>
                        <div style={{ width: '80px', height: '80px', objectFit: 'contain', marginTop: '8px', marginBottom: '3px' }} >
                            <img src={props.logoUrl ? props.logoUrl : Logo} alt="Logo" />
                        </div>
                        <div style={{ paddingLeft: '20px' }}>
                            <p style={{ fontWeight: 600 }}>Billed to</p>
                            <p style={{ fontSize: '14px', fontWeight: 600, color: 'gray' }}>{props.values.for.name}</p>
                            <p style={{ fontSize: '14px', fontWeight: 500, color: 'gray' }}>{props.values.for.email}</p>
                            <p style={{ fontSize: '14px', fontWeight: 500, color: 'gray' }}>{props.values.for.phoneNumber}</p>
                            <p style={{ fontSize: '14px', fontWeight: 500, color: 'gray' }}>{props.values.for.address.addressLine1}</p>
                            <p style={{ fontSize: '14px', fontWeight: 500, color: 'gray' }}>{`${props.values.for.address.city} ${props.values.for.address.regionOrState} ${props.values.for.address.postalCode}`}</p>
                        </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                        <p style={{ fontSize: '25px', fontWeight: 500, textTransform: 'uppercase' }}>Quote</p>
                        <p style={{ fontSize: '16px', fontWeight: 600, color: 'blue', textTransform: 'capitalize' }}>{props.values.from.name}</p>
                        <p style={{ fontSize: '14px', fontWeight: 500, color: 'gray' }}>{props.values.from.address.addressLine1}</p>
                        <p style={{ fontSize: '14px', fontWeight: 500, color: 'gray' }}>{`${props.values.from.address.city} ${props.values.from.address.regionOrState} ${props.values.from.address.postalCode}`}</p>
                    </div>
                </div>
                <hr style={{ border: '1px solid #EBEDF2', width: '100%' }} />
                <div style={{
                    display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
                    borderRadius: '20px', border: '1px solid #D7DAE0', margin: 'auto', marginTop: '50px', marginBottom: '50px', overflowX: 'auto',
                    width: '90%', minHeight: '600px', height: '100%'
                }}>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr style={{ backgroundColor: '#F9FAFC', borderBottom: '1px solid #D7DAE0' }}>
                                <th style={{ padding: '20px', textAlign: 'left', fontSize: '14px', width: '40%', wordWrap: 'break-word' }}>Services</th>
                                <th style={{ padding: '10px', textAlign: 'left', fontSize: '14px', width: '20%', }}>Qty</th>
                                <th style={{ padding: '10px', textAlign: 'left', fontSize: '14px', width: '20%', }}>Rate</th>
                                <th style={{ padding: '5px', textAlign: 'left', fontSize: '14px', width: '20%', }}>Line total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.values.lineItems.map((item, index) => (
                                <tr key={item.id} style={{ borderBottom: '1px solid #D7DAE0' }}>
                                    <td style={{
                                        padding: '20px', textAlign: 'left', height: "max-content", fontSize: '14px', wordWrap: 'break-word', whiteSpace: 'normal'
                                    }}>{item.description}</td>
                                    <td style={{ padding: '10px', textAlign: 'left', fontSize: '14px' }}>{item.quantity}</td>
                                    <td style={{ padding: '10px', textAlign: 'left', fontSize: '14px' }}> {item.rate !== undefined && item.rate !== null ? currencyFormatter('USD', Number(item.rate).toFixed(2)) : '0.00'}</td>
                                    <td style={{ padding: '10px', textAlign: 'left', fontSize: '14px' }}>
                                        {itemsLineTotal[index].lineTotal !== undefined && itemsLineTotal[index].lineTotal !== null ? currencyFormatter('USD', Number(itemsLineTotal[index].lineTotal).toFixed(2)) : '0.00'}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div style={{
                        display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: '70px'
                    }}>
                        <div style={{
                            display: 'flex', justifyContent: 'space-between', width: '100%',
                            paddingLeft: '30px', paddingRight: '30px', paddingTop: '10px', border: '1px solid #D7DAE0',
                        }}>
                            <div>
                                <p style={{ marginBottom: '10px', fontSize: '14px' }}>Subtotal</p>
                                <p style={{ marginBottom: '10px', fontSize: '14px' }}>Discount</p>
                                <p style={{ marginBottom: '10px', fontSize: '14px' }}>Tax ({props.values.taxAmount}%)</p>
                            </div>
                            <div>
                                <p style={{ marginBottom: '10px', fontSize: '14px' }}>{currencyFormatter('USD', subtotal.toFixed(2))}</p>
                                <p style={{ marginBottom: '10px', fontSize: '14px' }}>{discount !== undefined && discount !== null ? currencyFormatter('USD', Number(discount).toFixed(2)) : 0}</p>
                                <p style={{ marginBottom: '10px', fontSize: '14px' }}>{currencyFormatter('USD', tax.toFixed(2))}</p>
                            </div>
                        </div>
                        <div style={{
                            width: '100%', height: '50px', backgroundColor: '#F9FAFC', paddingLeft: '30px', paddingRight: '30px', display: 'flex',
                            justifyContent: 'space-between', alignItems: 'center', borderBottomLeftRadius: '20px',
                            borderBottomRightRadius: '20px'
                        }}>
                            <p style={{ color: 'blue', margin: '0', fontSize: '14px' }}>Total</p>
                            <p style={{ color: 'blue', margin: '0', fontSize: '14px', textAlign: 'left' }}>{currencyFormatter('USD', total.toFixed(2))}</p>
                        </div>
                    </div>
                </div>

                <div style={{
                    padding: '30px', backgroundColor: '#F9FAFC', display: 'flex',
                    justifyContent: 'space-around', alignItems: 'center', height: '50px'
                }}>
                    <p style={{ color: '#5E6470', fontSize: '14px', }}>{props.values.from.websiteUrl}</p>
                    <p style={{ color: '#5E6470', fontSize: '14px', }}>{props.values.from.phoneNumber}</p>
                    <p style={{ color: '#5E6470', fontSize: '14px', }}>{props.values.from.email}</p>
                </div>
            </div>
        </div>
    );
});