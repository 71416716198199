import { useFormikContext } from "formik";
import { useEffect } from "react";
import { IDocumentBase } from "../Types/Interface/IQuotes";

const usePreferredPriceDiscount = <T extends IDocumentBase>() => {

    const { values, setFieldValue } = useFormikContext<T>()

    useEffect(() => {
        if (values.discountAmount > 0) {
            setFieldValue("discountPercentage", 0)
        }
    }, [values.discountAmount]);

    useEffect(() => {
        if (values.discountPercentage > 0) {
            setFieldValue("discountAmount", 0)
        }
    }, [values.discountPercentage]);
    return { values }
}

export default usePreferredPriceDiscount