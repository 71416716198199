import {Box, Checkbox, Flex, FormControl, FormLabel, Input, Select, Text} from "@chakra-ui/react";
import React, {ChangeEvent} from "react"
import {IEmployee} from "../../../../Types/Interface/IPaystub";
import StateDropSelect from "../../../Components/StateDropSelect";
import DropSelect from "../../../Components/Inputs/DropSelect";
import {MaritalStatus} from "../../../../Types/Enums/PaymentModeEnum";
import {PatternFormat} from "react-number-format";

type EmployeeFormProps = {
    handleChange?: (e: ChangeEvent) => void
    employeeData: IEmployee,
}

type BaseInputProp = {
    value?: number | string,
    isChecked?: boolean,
    handleChange?: (e: ChangeEvent) => void
    setFieldValue?: (field: string, value: string | boolean | number) => void
}

const EmployeeInfoForm = ({handleChange, employeeData}: EmployeeFormProps) => {
  return (
      <Box mb={"36px"}>
        <EmployeeInfoFormHeading/>
          <EmployeeNameInput value={employeeData.name} handleChange={handleChange}/>
          <Flex gap={"24px"} my={"20px"}>
              <FormControl>
                  <FormLabel>
                      Last 4 of SSN
                  </FormLabel>
                  <PatternFormat type={"text"} customInput={Input} value={employeeData.ssn} name={"employee.ssn"} onChange={handleChange} format={"####"}/>
              </FormControl>
              <EmployeeAddressInput value={employeeData.address} handleChange={handleChange}/>
          </Flex>
          <Flex align={"end"} gap={"24px"} mb={"20px"}>
              <EmployeeCityInput value={employeeData.city} handleChange={handleChange}/>
              <EmployeeStateSelector value={employeeData.state} handleChange={handleChange}/>
              <EmployeeZipCodeInput value={employeeData.zipcode}  handleChange={handleChange}/>
          </Flex>
          <Flex gap={'24px'} mb={"20px"}>
              <FormControl mt={"20px"}>
                  <FormLabel>
                      Employee ID
                  </FormLabel>
                  <Input type={"text"} value={employeeData.eid} name={"employee.eid"} onChange={handleChange}/>
              </FormControl>
              <DropSelect
                  value={employeeData.maritalStatus}
                  onChange={handleChange}
                  isRequired label={"Marital Status"} name={`employee.maritalStatus`}>
                  <option disabled>Select Marital Status</option>
                  <option value={MaritalStatus.Married}>{MaritalStatus.Married}</option>
                  <option value={MaritalStatus.Single}>{MaritalStatus.Single}</option>
              </DropSelect>

          </Flex>
          <Checkbox>Check this box to adjust hire date if hired recently</Checkbox>
      </Box>
  )
}


const EmployeeInfoFormHeading = () => {
  return (
      <Flex justifyContent={"space-between"} borderBottomWidth={"0.35px"} borderBottomColor={"black"} pb={"10px"}>
        <Text fontSize={"32px"} fontWeight={"semibold"} color={"#208c55"}>
          Employee&apos;s Information
        </Text>
        <Checkbox fontSize={"18px"}>Contractor</Checkbox>
      </Flex>
  )
}

const EmployeeNameInput = ({handleChange, value}: BaseInputProp) => {
    return (
        <FormControl mt={"20px"}>
            <FormLabel>
                Employee Name
            </FormLabel>
            <Input type={"text"} value={value} name={"employee.name"} onChange={handleChange}/>
        </FormControl>
    )
}

const EmployeeRoleInput = ({handleChange, value}: BaseInputProp) => {
    return (
        <FormControl>
            <FormLabel>
                Role (Optional)
            </FormLabel>
            <Input value={value} onChange={handleChange} name={"employee.role"} type={"text"}/>
        </FormControl>
    )
}

const EmployeeAddressInput = ({handleChange, value}: BaseInputProp) => {
    return (
        <FormControl>
            <FormLabel>
                Employee Address
            </FormLabel>
            <Input value={value} onChange={handleChange} name={"employee.address"} type={"text"}/>
        </FormControl>
    )
}

const EmployeeCityInput = ({handleChange, value}: BaseInputProp) => {
    return (
        <FormControl flexBasis={"50%"}>
            <FormLabel>
                City
            </FormLabel>
            <Input value={value} onChange={handleChange} name={"employee.city"} type={"text"}/>
        </FormControl>
    )
}

const EmployeeZipCodeInput = ({handleChange, value}: BaseInputProp) => {
    return (
        <FormControl flexBasis={"25%"}>
            <FormLabel>
                ZIP Code
            </FormLabel>
            <Input value={value} onChange={handleChange} name={"employee.zipcode"} type={"text"}/>
        </FormControl>
    )
}

const EmployeeStateSelector = ({handleChange, value}: BaseInputProp) => {
    return (
        <StateDropSelect flexBasis={"40%"} label={"State"} placeholder={"Select state"} notFormik name={"employee.state"} value={value} onChange={handleChange}/>
    )
}


export default EmployeeInfoForm