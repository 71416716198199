import {Box, Button, Center, Checkbox, Flex} from "@chakra-ui/react"
import React, {useState} from "react"
import {IPaystubData} from "../../../Types/Interface/IPaystub";
import StubTemplate from "../../Templates/StubTemplate";

const PayStubPreviewSection = ({data, logo}: {data: IPaystubData, logo: string}) => {
    const [currentPayStub, setCurrentPayStub] = useState(0)


  return (
      <Box pos={"sticky"} pt={"32px"} h={"max-content"} flexBasis={"50%"} top={"40px"}>
          <Box w={"full"} mb={"32px"}>
              <StubTemplate paystub={data.payStubs[currentPayStub]} company={data.company} employee={data.employee}/>
          </Box>
          <Flex alignItems={"center"} justifyContent={"space-between"} mb={"16px"}>
              <Button onClick={() => setCurrentPayStub(prev => prev - 1)} disabled={currentPayStub === 0}>
                  prev
              </Button>
              {
                  currentPayStub + 1
              }
              of {data.info.payStubCount}
              <Button onClick={() => setCurrentPayStub(prev => prev + 1)} disabled={currentPayStub + 1 === data.info.payStubCount}>
                  Next
              </Button>
          </Flex>
          <Box borderBottom={"1px"} pb={"24px"}  borderBottomColor={"#00000080"}>
              <Checkbox mb={"12px"}> I agree not to use this site for illegal purposes</Checkbox>
              <Checkbox> I accept our Term of Use and Privacy Policy</Checkbox>
          </Box>
          <Center>
              <Button variant={"solid"} h={"78px"} w={"350px"} color={"#ffffff"} borderRadius={"30px"} mt={"28px"} backgroundColor={"#208c55"}>
                  CHECKOUT
              </Button>
          </Center>
      </Box>
  )
}

export default PayStubPreviewSection