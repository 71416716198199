import BusinessInfo from "../../../Components/Forms/BusinessInfo";
import React from "react"


const prefix = "for."
const addressPrefix = `${prefix}address.`

const QuoteFor = ()=>{
    return (
        <BusinessInfo
            formHeading={"Quotation for"}
            currentStep={2}
            steps={3}
            prefix={prefix}
            addressPrefix={addressPrefix}/>
    );
}

export default QuoteFor;