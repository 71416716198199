import { Box, Checkbox, Flex, FormControl, FormLabel, Input } from "@chakra-ui/react";
import BusinessFormsHeading from "../../../Components/Forms/BusinessFormsHeading";
import FormikCheckBox from "../../../Components/Formik/FormikCheckBox";
import { IEmployee, IListValue } from "../../../../Types/Interface/IW2";
import { useAppDispatch } from "../../../../Hooks/useAppDispatch";
import React, { useState } from "react"
import {
    setEmployeeAddress,
    setEmployeeAddressState,
    setEmployeeAllocatedTips,
    setEmployeeCity,
    setEmployeeControlNumber,
    setEmployeeDependentCare,
    setEmployeeFederalTax,
    setEmployeeFirstName,
    setEmployeeIncome, setEmployeeIsStatutory,
    setEmployeeLastName, setEmployeeLocalIncome, setEmployeeLocality, setEmployeeLocalTax, setEmployeeMaritalStatus,
    setEmployeeMedicareTax, setEmployeeRetirementPlan, setEmployeeSickPay,
    setEmployeeSocialTax,
    setEmployeeSocialTips, setEmployeeSsn,
    setEmployeeStateTax,
    setEmployeeTaxCodeAAmount,
    setEmployeeTaxCodeACode,
    setEmployeeTaxCodeBAmount,
    setEmployeeTaxCodeBCode,
    setEmployeeTaxCodeCAmount,
    setEmployeeTaxCodeCCode,
    setEmployeeTaxCodeDAmount,
    setEmployeeTaxCodeDCode,
    setEmployeeZipcode, setEmployeeEmail,
} from "../../../../Store/Slices/w2Slice";
import { ChangeEvent, memo } from "react";
import { taxCodes } from "../../../../Constants/JsonConstants";
import { PatternFormat } from "react-number-format";
import TextInput from "Pages/Components/Inputs/TextInput";
import DropSelect from "Pages/Components/Inputs/DropSelect";
import StateDropSelect from "../../../Components/StateDropSelect";
import useEmployeeTaxCode from "Hooks/useEmployeeTaxCode";

type EmployeeInfoProps = {
    index: number
    employee: IEmployee
}

const EmployeeInfo = memo(function EmployeeInfo({ index, employee }: EmployeeInfoProps) {
    const [showDetails, setShowDetails] = useState(false)
    const dispatch = useAppDispatch()
    useEmployeeTaxCode(index)

    const setValue = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>): IListValue => {
        return { index, value: event.target.value }
    }

    const prefix = `employees[${index}]`
    return (
        <Box mb={"48px"}>
            <BusinessFormsHeading title={`Employee ${index + 1} Info`} steps={3} currentStep={3} />
            <Flex gap={"24px"}>
                <FormControl
                    flexBasis={"30%"}
                    isRequired mt={"20px"}>
                    <FormLabel>
                        SSN
                    </FormLabel>
                    <PatternFormat
                        focusBorderColor="primary"
                        customInput={Input}
                        format={"###-##-####"}
                        value={employee.ssn}
                        onChange={(event) => dispatch(setEmployeeSsn(setValue(event)))}
                        name={`${prefix}.ssn`}
                        type={"text"}
                    />
                </FormControl>
                <DropSelect
                    isRequired value={employee.maritalStatus} flexBasis={"30%"} onChange={(event) => dispatch(setEmployeeMaritalStatus(setValue(event)))} label={"Marital Status"} name={`${prefix}.maritalStatus`}>
                    <option value={"married"}>Married</option>
                    <option value={"single"}>Single</option>
                </DropSelect>
                <TextInput
                    isRequired label={"Email"} value={employee.email}
                    onChange={(event) => dispatch(setEmployeeEmail(setValue(event)))}
                    name={`${prefix}.email`} type={"email"}
                    flexBasis={"40%"}
                />
            </Flex>
            <Flex gap={"24px"}>
                <TextInput
                    isRequired value={employee.firstName}
                    onChange={(event) => dispatch(setEmployeeFirstName(setValue(event)))} label={"First Name"}
                    name={`${prefix}.firstName`} type={"text"} />
                <TextInput
                    isRequired label={"Last Name"} value={employee.lastName}
                    onChange={(event) => dispatch(setEmployeeLastName(setValue(event)))}
                    name={`${prefix}.lastName`} type={"text"} />
            </Flex>

            <Flex gap={"12px"}>

                <TextInput
                    isRequired value={employee.address.stateAddress} onChange={(event) => dispatch(setEmployeeAddress(setValue(event)))} label={"Address"} name={`${prefix}.address.stateAddress`}
                    type={"text"} />
            </Flex>
            <Flex gap={"12px"} align={"end"}>
                <TextInput
                    isRequired
                    value={employee.address.city}
                    onChange={(event) => dispatch(setEmployeeCity(setValue(event)))}
                    flexBasis={"30%"} label={"City"}
                    name={`${prefix}.address.city`} type={"text"} />
                <StateDropSelect isRequired label={"State"} placeholder={"State"} flexBasis={"35%"} notFormik={true} value={employee.address.state} onChange={(event) => dispatch(setEmployeeAddressState(setValue(event)))} name={`${prefix}.address.state`} />
                <TextInput
                    isRequired value={employee.address.zipcode}
                    onChange={(event) => dispatch(setEmployeeZipcode(setValue(event)))} flexBasis={"20%"}
                    label={"Zip code"}
                    name={`${prefix}.address.zipcode`} type={"text"} />
            </Flex>
            <Flex gap={"12px"} mb={"32px"} direction={{ base: "column", md: "row" }}>
                <TextInput
                    isRequired
                    value={employee.income}
                    onChange={(event) => dispatch(setEmployeeIncome(setValue(event)))}
                    flexBasis={"30%"} label={"Annual Income"} name={`${prefix}.income`}
                    type={"number"} />
                <TextInput
                    value={employee.controlNumber}
                    onChange={(event) => dispatch(setEmployeeControlNumber(setValue(event)))}
                    flexBasis={"35%"} label={"Control Number (optional)"}
                    name={`${prefix}.controlNumber`} type={"text"} />

            </Flex>
            <BusinessFormsHeading title={"Additional Info"} />
            <Flex gap={"24px"} direction={{ base: "column", md: "row" }}>
                <TextInput
                    value={employee.tax.federalIncome}
                    onChange={(event) => dispatch(setEmployeeFederalTax(setValue(event)))}
                    label={"Federal Income tax withheld"}
                    name={`${prefix}.tax.federalIncome`} type={"number"} />
                <TextInput value={employee.tax.stateIncome} onChange={(event) => dispatch(setEmployeeStateTax(setValue(event)))} label={"State Income tax withheld"}
                    name={`${prefix}.tax.stateIncome`} type={"number"} />
                <TextInput value={employee.tax.socialSecurity} onChange={(event) => dispatch(setEmployeeSocialTax(setValue(event)))} label={"Social Security tax withheld"}
                    name={`${prefix}.tax.socialSecurity`} type={"number"} />
            </Flex>
            <Flex gap={"24px"} direction={{ base: "column", md: "row" }}>
                <TextInput flexBasis={"27%"} value={employee.tax.medicare} onChange={(event) => dispatch(setEmployeeMedicareTax(setValue(event)))} label={"Medicare tax withheld"}
                    name={`${prefix}.tax.medicare`} type={"number"} />

            </Flex>
            <Flex mt={"32px"} justifyContent={"end"}>
                <Checkbox isChecked={showDetails} onChange={() => setShowDetails((prevState) => !prevState)}>
                    Show Additional options
                </Checkbox>
            </Flex>
            {
                showDetails && (
                    <>
                        <Flex gap={"24px"} direction={{ base: "column", md: "row" }}>
                            <TextInput value={employee.dependentCareBenefits} onChange={(event) => dispatch(setEmployeeDependentCare(setValue(event)))} flexBasis={"30%"} label={"Dependent care benefits"}
                                name={`${prefix}.dependentCareBenefits`} type={"number"} />
                            <TextInput value={employee.tips?.socialSecurity} onChange={(event) => dispatch(setEmployeeSocialTips(setValue(event)))} flexBasis={"30%"} label={"Social care tips"}
                                name={`${prefix}.tips.socialSecurity`} type={"number"} />
                            <TextInput value={employee.tips?.allocated} onChange={(event) => dispatch(setEmployeeAllocatedTips(setValue(event)))} flexBasis={"30%"} label={"Allocated tips"} name={`${prefix}.tips.allocated`}
                                type={"number"} />
                        </Flex>
                        <Flex gap={"24px"} direction={{ base: "column", md: "row" }}>
                            <Flex gap={"12px"}>
                                <DropSelect value={employee.taxCodeA?.code} onChange={(event) => dispatch(setEmployeeTaxCodeACode(setValue(event)))} label={"Tax code 12A"} placeholder={"Code"}
                                    name={`${prefix}.taxCodeA.code`}>
                                    {
                                        taxCodes.map(taxCode => <option key={taxCode.code}
                                            value={taxCode.code}>{taxCode.code}: {taxCode.description}</option>)
                                    }
                                </DropSelect>
                                <TextInput value={employee.taxCodeA?.amount} onChange={(event) => dispatch(setEmployeeTaxCodeAAmount(setValue(event)))} label={"Amount"} name={`${prefix}.taxCodeA.amount`}
                                    type={"number"} />
                            </Flex>
                            <Flex gap={"12px"}>
                                <DropSelect value={employee.taxCodeB?.code} onChange={(event) => dispatch(setEmployeeTaxCodeBCode(setValue(event)))} label={"Tax code 12B"} placeholder={"Code"}
                                    name={`${prefix}.taxCodeB.code`}>
                                    {
                                        taxCodes.map(taxCode => <option key={taxCode.code}
                                            value={taxCode.code}>{taxCode.code}: {taxCode.description}</option>)
                                    }
                                </DropSelect>
                                <TextInput value={employee.taxCodeB?.amount} onChange={(event) => dispatch(setEmployeeTaxCodeBAmount(setValue(event)))} label={"Amount"} name={`${prefix}.taxCodeB.amount`}
                                    type={"number"} />
                            </Flex>
                        </Flex>
                        <Flex gap={"24px"} direction={{ base: "column", md: "row" }}>
                            <Flex gap={"12px"}>
                                <DropSelect value={employee.taxCodeC?.code} onChange={(event) => dispatch(setEmployeeTaxCodeCCode(setValue(event)))} label={"Tax code 12C"} placeholder={"Code"}
                                    name={`${prefix}.taxCodeC.code`}>
                                    {
                                        taxCodes.map(taxCode => <option key={taxCode.code}
                                            value={taxCode.code}>{taxCode.code}: {taxCode.description}</option>)
                                    }
                                </DropSelect>
                                <TextInput value={employee.taxCodeC?.amount} onChange={(event) => dispatch(setEmployeeTaxCodeCAmount(setValue(event)))} label={"Amount"} name={`${prefix}.taxCodeC.amount`}
                                    type={"number"} />
                            </Flex>
                            <Flex gap={"12px"}>
                                <DropSelect value={employee.taxCodeD?.code} onChange={(event) => dispatch(setEmployeeTaxCodeDCode(setValue(event)))} label={"Tax code 12D"} placeholder={"Code"}
                                    name={`${prefix}.taxCodeD.code`}>
                                    {
                                        taxCodes.map(taxCode => <option key={taxCode.code}
                                            value={taxCode.code}>{taxCode.code}: {taxCode.description}</option>)
                                    }
                                </DropSelect>
                                <TextInput value={employee.taxCodeD?.amount} onChange={(event) => dispatch(setEmployeeTaxCodeDAmount(setValue(event)))} label={"Amount"} name={`${prefix}.taxCodeD.amount`}
                                    type={"number"} />
                            </Flex>
                        </Flex>
                        <Flex>
                            <FormikCheckBox
                                isChecked={employee.isStatutory}
                                onChange={() => {
                                    dispatch(setEmployeeIsStatutory({ index, value: !employee.isStatutory }))
                                }}
                                label={"Statutory Employee"}
                                name={`${prefix}.isStatutory`} />
                            <FormikCheckBox isChecked={employee.hasRetirementPlan}
                                onChange={() => {
                                    dispatch(setEmployeeRetirementPlan({ index, value: !employee.hasRetirementPlan }))
                                }}
                                label={"Retirement Plan"}
                                name={`${prefix}.hasRetirementPlan`} />
                            <FormikCheckBox isChecked={employee.receivesSickPay} onChange={() => {
                                dispatch(setEmployeeSickPay({ index, value: !employee.receivesSickPay }))
                            }} label={"Third Party Sick pay"}
                                name={`${prefix}.receivesSickPay`} />
                        </Flex>
                        <Flex gap={"24px"} direction={{ base: "column", md: "row" }}>
                            <TextInput value={employee.localIncome?.income} onChange={(event) => dispatch(setEmployeeLocalIncome(setValue(event)))} label={"Local wages, tips etc"}
                                name={`${prefix}.localIncome.income`} type={"number"} />
                            <TextInput value={employee.localIncome?.tax} onChange={(event) => dispatch(setEmployeeLocalTax(setValue(event)))} label={"Local income tax"}
                                name={`${prefix}.localIncome.tax`} type={"number"} />
                            <TextInput value={employee.localIncome?.name} onChange={(event) => dispatch(setEmployeeLocality(setValue(event)))} label={"Locality name"}
                                name={`${prefix}.localIncome.name`} type={"text"} />
                        </Flex>
                    </>
                )
            }

        </Box>
    )
})

export default EmployeeInfo;