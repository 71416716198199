import {Flex} from "@chakra-ui/react";
import AppLayout from "../../Layouts/AppLayout";
import PayStubForms from "./Components/PayStubForms";
import PayStubPreviewSection from "./Components/PayStubPreviewSection";
import React, { useState} from "react"
import {useAppSelector} from "../../Hooks/useAppSelector";
import {selectPaystubData} from "../../Store/Slices/paystubSlice";
import {useFormik} from "formik";
import useHandleUploadLogo from "../../Hooks/useHandleUploadLogo";
import usePayModeEffect from "../../Hooks/usePayModeEffect";

const PayStub = () => {
    const data = useAppSelector(selectPaystubData)
    const [logo, setLogo] = useState("")
    const {handleChange, handleSubmit, values, setFieldValue,} = useFormik({initialValues: data,
        onSubmit: (values, formikHelpers) => {
        formikHelpers.setSubmitting(true)
            console.log(values)
        }})
    usePayModeEffect({values, setFieldValue})
    const handleLogoUpload = useHandleUploadLogo({companyName: values.company.name, companyEmail: values.company.email, setLogoUrl: (logo) => setLogo(logo)})


  return (
      <AppLayout isForm>
      {/*  Forms and Preview*/}
          <form onSubmit={handleSubmit}>
              <Flex w={"full"} px={"50px"} py={"50px"} gap={"64px"} pos={"relative"}>
                  <PayStubForms handleChange={handleChange} values={values} setFieldValue={setFieldValue}/>
                  <PayStubPreviewSection data={values} logo={logo}/>
              </Flex>
          </form>
      </AppLayout>
  )
}


export default PayStub