import {Box, Button, Flex, Text} from "@chakra-ui/react";
import PlusIcon from "../../../../Components/PlusIcon";
import React from "react";

const CustomDeductions = ({index}: {index: number}) => {
    return (
        <Flex align={"center"} justifyContent={"space-between"}>
            <Box>
                <Text color={"#208c55"} fontSize={"18px"}>
                    Customer Deduction
                </Text>
                {
                    index === 0 && (
                        <Text color={"#0000007F"} fontSize={"18px"}>
                            Deduct insurance, 401k, other taxes etc.
                        </Text>
                    )
                }
            </Box>
            {
                index === 0 && (
                    <Button color={"#208c55"}  borderColor={"#208c55"} fontWeight={"normal"} leftIcon={<PlusIcon/>} variant={"outline"}>
                        Add Deductions
                    </Button>
                )
            }
        </Flex>
    )
}

export default CustomDeductions