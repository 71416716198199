// export enum paymentModeEnums {
//     Hourly = 0,
//     Salary = 1
// }
//
// export enum paymentScheduleEnums {
//     Weekly = 'Weekly',
//     BiWeekly= 'Bi-Weekly',
//     SemiMonthly = 'Semi-Monthly',
//     Monthly = 'Monthly',
//     Quarterly = 'Quarterly',
//     SemiAnnually = 'Semi-Annually',
//     Annually = 'Annually'
// }
//
// export const payPeriodMultiplier  = {
//     [paymentScheduleEnums.Weekly]: 52,
//     [paymentScheduleEnums.BiWeekly]: 26,
//     [paymentScheduleEnums.SemiMonthly]: 24,
//     [paymentScheduleEnums.Monthly]: 12,
//     [paymentScheduleEnums.Quarterly]: 4,
//     [paymentScheduleEnums.SemiAnnually]: 2,
//     [paymentScheduleEnums.Annually]: 1
// };
//
// export const  totalHoursMultiplier = {
//     [paymentScheduleEnums.Weekly]: 40, // Example hours for weekly schedule
//     [paymentScheduleEnums.BiWeekly]: 80,
//     [paymentScheduleEnums.SemiMonthly]: 87, // Example for semi-monthly (calculated 52 weeks / 12 months / 2)
//     [paymentScheduleEnums.Monthly]: 173, // Example for monthly
//     [paymentScheduleEnums.Quarterly]: 173, // Example for quarterly
//     [paymentScheduleEnums.SemiAnnually]: 173, // Example for semi-annually
//     [paymentScheduleEnums.Annually]: 173 // Example for annually
// }
//
// export enum totalHoursMultiplierEnum {
//     weekly = 40,
//     biWeekly = 80,
//     semiMonthly = 87,
//     monthly = 173,
//     quarterly = 173,
//     semiAnnually = 173,
//     annually = 173
// }
import {getDaysInMonth, getDaysInYear, getISOWeek, getISOWeeksInYear, getMonth, getQuarter} from "date-fns";
import {getSemiMonthlyPeriod} from "../../Services/utils";

export enum PaymentMode {
    Hourly = "Hourly",
    Salary = "Salary",
}

export enum DeductionType {
    AdditionalDeduction = "AdditionalDeduction",
    StateTax = "StateTax",
    FederalTax = "FederalTax",
    Medicare = "Medicare",
    SocialSecurity = "SocialSecurity"
}

export enum PaySchedule {
    Weekly = "Weekly",
    BiWeekly = "Bi-Weekly",
    SemiMonthly = "Semi-Monthly",
    Monthly = "Monthly",
    Quarterly = "Quarterly",
    SemiAnnually = "Semi-Annually",
    Annually = "Annually",
}
export enum MaritalStatus {
    Married = "Married",
    Single = "Single",
}

interface IPaymentScheduleInfo{
    periodRange: number
    hours: number
    payPeriods: ()=> number
    getCurrentPayPeriod: (payDate?: string ) => number
}

export const paymentScheduleInfo: {[key in PaySchedule]: IPaymentScheduleInfo} = {
    [PaySchedule.Weekly]: {
        periodRange: 7,
        hours: 40,
        payPeriods: () => getISOWeeksInYear(new Date()),
        getCurrentPayPeriod: (payDate) => payDate ? getISOWeek(payDate) : getISOWeek(new Date()),
    },
    [PaySchedule.BiWeekly]: {
        periodRange: 14,
        hours: 80,
        payPeriods: () => 26,
        getCurrentPayPeriod: (payDate) => payDate ? Math.ceil(getISOWeek(payDate) / 2) : Math.ceil(getISOWeek(new Date()) / 2),
    },
    [PaySchedule.SemiMonthly]:{
        periodRange: 15,
        hours: 87,
        payPeriods: () => 24,
        getCurrentPayPeriod: (payDate) => payDate ? getSemiMonthlyPeriod(payDate) : getSemiMonthlyPeriod(new Date()),
    },
    [PaySchedule.Monthly]: {
        periodRange: getDaysInMonth(new Date()),
        hours: 176,
        payPeriods: () => 12,
        getCurrentPayPeriod: (payDate) => (payDate ? getMonth(payDate) : getMonth(new Date())) + 1,
    },
    [PaySchedule.Quarterly]: {
        periodRange: 90,
        hours: 528,
        payPeriods: () => 4,
        getCurrentPayPeriod: (payDate) => payDate ? getQuarter(payDate) : getQuarter(new Date()),
    },
    [PaySchedule.SemiAnnually]: {
        periodRange: 182,
        hours: 2080,
        payPeriods: () => 2,
        getCurrentPayPeriod: (payDate) =>  payDate ? Math.ceil(getQuarter(payDate) / 2) : Math.ceil(getQuarter(new Date()) / 2),
    },
    [PaySchedule.Annually]: {
        periodRange: getDaysInYear(new Date()),
        hours: 2080,
        payPeriods: () => 1,
        getCurrentPayPeriod: () => 1
    }
}
